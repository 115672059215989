/***********/
/* Blocks */
@media screen and (max-width: 1199px) {
	.actor__img {
		width: auto;
	}
}

@media screen and (max-width: 991px) {
	.actor__img {
		text-align: center;
	}
}

@media screen and (max-width: 991px) {
	.footer__wrapper {
		flex-wrap: wrap;
	}
	.footer__wrapper div {
		padding: 5px;
	}
}

@media screen and (max-width: 991px) {
	.person__info {
		text-align: left;
	}
	.person__img {
		text-align: center;
		margin-bottom: 20px;
	}
}
